// src/pages/About.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const About = () => {
    return (
        <AboutContainer>
            <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                About YourDiary
            </motion.h1>
            <motion.p initial={{ y: 50 }} animate={{ y: 0 }} transition={{ type: 'spring', stiffness: 100 }}>
                YourDiary is a secure platform where you can write, create drawings, record voice notes, and upload files with
                encryption so strong that even developers can’t access your data.
            </motion.p>

            <Features>
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: 'spring', stiffness: 200 }}
                >
                    <h2>Advanced Encryption</h2>
                    <p>Your data is secured using state-of-the-art encryption protocols.</p>
                </motion.div>
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: 'spring', stiffness: 200 }}
                >
                    <h2>Multi-Platform Support</h2>
                    <p>Access YourDiary from Android devices and the web securely, anytime, anywhere.</p>
                </motion.div>
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    transition={{ type: 'spring', stiffness: 200 }}
                >
                    <h2>Privacy First</h2>
                    <p>We put privacy above all, making sure your data remains confidential and secure.</p>
                </motion.div>
            </Features>
        </AboutContainer>
    );
};

export default About;

// Styled Components
const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 20px; /* Add padding to avoid being under the fixed header */
  background-color: #1f1f1f;
  color: white;
  text-align: center;

  h1 {
    font-size: 3rem;
    font-family: 'Orbitron', sans-serif;
    margin-bottom: 30px;
  }

  p {
    font-size: 1.5rem;
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
    max-width: 800px;
  }
`;

const Features = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  gap: 30px;

  div {
    background-color: #282c34;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    transition: background-color 0.3s ease;

    h2 {
      font-size: 1.8rem;
      font-family: 'Orbitron', sans-serif;
      color: #61dafb;
    }

    p {
      font-size: 1.2rem;
      font-family: 'Roboto', sans-serif;
      margin-top: 10px;
    }

    &:hover {
      background-color: #20252a;
    }
  }
`;
