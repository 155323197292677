// src/pages/PrivacyPolicy.js
import React from 'react';
import styled from 'styled-components';

const PrivacyPolicy = () => {
    return (
        <PolicyContainer>
            <h1>Privacy Policy</h1>

            <Section>
                <h2>What does this privacy policy cover?</h2>
                <p>This privacy policy covers our treatment of personally identifiable information ("personal information") that we gather when you are accessing or using our Mobile App and Products. This policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.</p>
            </Section>

            <Section>
                <h2>What information does YourDiary collect?</h2>
                <p>We collect users' email and name after Google or Facebook login. We store users' diary data with AES encryption to ensure privacy. With this encryption, only the logged-in user can read their data. Only the special combination of your social login IDs and YourDiary token can make your content readable only to you. Our developers cannot read your content.</p>
            </Section>

            <Section>
                <h2>Google user's data policy</h2>
                <p>We collect email, name, and Google ID using Google login. Email and name are stored in plain text in the database, which is used for identifying users. Google ID is stored as an MD5 hash in the database, which is also used as the user's unique key for data encryption.</p>
            </Section>

            <Section>
                <h2>Facebook user's data policy</h2>
                <p>We collect email, name, and Facebook ID using Facebook login. Email and name are stored in plain text in the database, which is used for identifying users. Facebook ID is stored as an MD5 hash in the database, which is also used as the user's unique key for data encryption.</p>
            </Section>

            <Section>
                <h2>Information you provide to us:</h2>
                <p>We use Facebook/Google login to authenticate users. We get the user's email and name. We do not store social login tokens with us. We destroy social login tokens after login and generate our own token for authentication validation. Facebook and Google do not have access to any data of YourDiary.</p>
            </Section>

            <Section>
                <h2>How user data is processed?</h2>
                <p>We store users' name and email with us. This data is used to validate the user's identity at the time of login. All other data is stored encrypted, and only the user can decrypt it at their end.</p>
            </Section>

            <Section>
                <h2>How can users delete their data?</h2>
                <p>Users can send an email to <EmailLink href="mailto:contact@yourdiary.in">contact@yourdiary.in</EmailLink> with the registered email ID and YourDiary ID to delete their entire account data. This may take up to 24 hours.</p>
            </Section>

            <Section>
                <h2>Changes to this privacy policy</h2>
                <p>We may amend this privacy policy from time to time. The use of information we collect now is subject to the privacy policy in effect at the time such information is used. If we make changes in the way we use personal information, we will notify you by posting an announcement on our Mobile App. You are bound by any changes to the privacy policy when you use the Mobile App or the Product after such changes have been first posted.</p>
            </Section>
        </PolicyContainer>
    );
};

export default PrivacyPolicy;

// Styled components
const PolicyContainer = styled.div`
  padding: 100px 20px 20px; /* Add padding to the top to prevent overlap with the header */
  max-width: 800px;
  margin: 0 auto;
  color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'black')};
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#1f1f1f' : '#f5f5f5')};
  
  h1 {
    font-size: 2.5rem;
    color: #f68933;
    text-align: center;
  }

  h2 {
    color: #333;
    margin-top: 20px;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin: 10px 0;
  }
`;

const Section = styled.div`
  margin: 20px 0;
`;

const EmailLink = styled.a`
  color: #1155cc;
  text-decoration: underline;
`;
