// src/contexts/ThemeContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);

    // Function to detect system preference and set theme
    useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        const handleSystemThemeChange = (e) => {
            setIsDarkMode(e.matches);
        };

        // Set initial theme based on system preference
        setIsDarkMode(mediaQuery.matches);

        // Listen for changes in system theme
        mediaQuery.addEventListener('change', handleSystemThemeChange);

        // Cleanup listener on unmount
        return () => mediaQuery.removeEventListener('change', handleSystemThemeChange);
    }, []);

    return (
        <ThemeContext.Provider value={{ isDarkMode }}>
            {children}
        </ThemeContext.Provider>
    );
};
