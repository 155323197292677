// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; // Import Link for routing

const Footer = () => {
    return (
        <FooterContainer>
            <FooterContent>
                <p>&copy; {new Date().getFullYear()} YourDiary. All Rights Reserved.</p>

                <PrivacyLink>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </PrivacyLink>
                <SocialIcons>
                    <a href="https://www.instagram.com/yourdiary.in/" target="_blank" rel="noopener noreferrer">Instagram</a>
                    <a href="https://www.facebook.com/yourdiary.in" target="_blank" rel="noopener noreferrer">Facebook</a>
                    <a href="https://www.youtube.com/@YourDiary-ui6zy" target="_blank" rel="noopener noreferrer">YouTube</a>
                    <a href="https://www.linkedin.com/company/podcopic/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </SocialIcons>
            </FooterContent>
        </FooterContainer>
    );
};

export default Footer;

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #101010;
  padding: 20px 0;
  text-align: center;
  color: white;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SocialIcons = styled.div`
  margin-top: 15px; /* Add some margin between the text and social icons */

  a {
    color: white;
    margin: 0 15px;
    text-decoration: none;
    font-size: 1.1rem;
    transition: color 0.3s;

    &:hover {
      color: #61dafb;
    }
  }
`;

const PrivacyLink = styled.div`
  a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
      color: #61dafb;
    }
  }
`;
