// src/pages/Contact.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Contact = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        let valid = true;
        let errors = {};

        if (!formData.name) {
            errors.name = 'Name is required';
            valid = false;
        }

        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Valid email is required';
            valid = false;
        }

        if (!formData.message) {
            errors.message = 'Message cannot be empty';
            valid = false;
        }

        setErrors(errors);
        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Generate the mailto link
            const mailtoLink = `mailto:contact@yourdiary.in?subject=Contact Form Submission from ${formData.name}&body=Name: ${formData.name}%0AEmail: ${formData.email}%0AMessage: ${formData.message}`;

            // Open the user's email client
            window.location.href = mailtoLink;

            // Reset form data
            setFormData({ name: '', email: '', message: '' });
        }
    };

    return (
        <ContactContainer>
            <motion.h1 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                Contact Us
            </motion.h1>
            <Form onSubmit={handleSubmit}>
                <InputWrapper>
                    <Label>Name</Label>
                    <Input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    {errors.name && <ErrorText>{errors.name}</ErrorText>}
                </InputWrapper>

                <InputWrapper>
                    <Label>Email</Label>
                    <Input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    {errors.email && <ErrorText>{errors.email}</ErrorText>}
                </InputWrapper>

                <InputWrapper>
                    <Label>Message</Label>
                    <TextArea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                    {errors.message && <ErrorText>{errors.message}</ErrorText>}
                </InputWrapper>

                <SubmitButton whileHover={{ scale: 1.1 }}>Send Message</SubmitButton>
            </Form>
        </ContactContainer>
    );
};

export default Contact;

// Styled components
const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-color: #1f1f1f;
  color: white;
  text-align: center;
  padding: 20px; /* Added padding for mobile view */

  @media (max-width: 768px) {
    padding: 15px; /* Adjusted padding for smaller screens */
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px; /* Adjust form padding for smaller screens */
  }
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 0 10px; /* Add padding around inputs to prevent touching boundaries on mobile */

  @media (max-width: 768px) {
    padding: 0 5px; /* Adjust input padding for mobile screens */
  }
`;

const Label = styled.label`
  font-size: 1.2rem;
  margin-bottom: 5px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #282c34;
  color: white;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Adjust font size for mobile */
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #282c34;
  color: white;
  height: 150px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Adjust font size for mobile */
  }
`;

const SubmitButton = styled(motion.button)`
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #61dafb;
  color: #282c34;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  margin-top: 10px;

  &:hover {
    background-color: #21a1f1;
  }

  @media (max-width: 768px) {
    width: 100%; /* Full-width button on mobile */
    font-size: 1rem; /* Adjust button font size for mobile */
  }
`;

const ErrorText = styled.span`
  color: #f50057;
  font-size: 0.9rem;
  margin-top: 5px;
  display: block;
`;
