// src/pages/Home.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import { FaLock } from 'react-icons/fa';  // Import lock icon for encryption symbol

const Home = () => {
    const { isDarkMode } = useTheme();

    return (
        <HomeContainer isDarkMode={isDarkMode}>
            <SubtleBackground />
            <FloatingLockIcon />
            <motion.h1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                Welcome to YourDiary
            </motion.h1>
            <motion.p
                initial={{ y: 50 }}
                animate={{ y: 0 }}
                transition={{ type: 'spring', stiffness: 100 }}
            >
                YourDiary is built with a focus on complete privacy and security. Whether you're uploading personal files, saving passwords, or writing notes, your data is encrypted with the highest standards. Not even our developers can access it. With 100% data security, YourDiary is your trusted space for all your private information.
            </motion.p>
            <ButtonContainer>
                <motion.a
                    href="https://play.google.com/store/apps/details?id=in.yourdiary.app.yourdiary"
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                >
                    Get the Android App
                </motion.a>
                <motion.a
                    href="https://web.yourdiary.in/"
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                >
                    Try the Web App
                </motion.a>
            </ButtonContainer>
        </HomeContainer>
    );
};

export default Home;

// Subtle flowing background effect (representing encryption lines or data flow)
const backgroundFlow = keyframes`
  0% { background-position: 0 0; }
  100% { background-position: 100% 100%; }
`;

const HomeContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    padding: 20px;
    background: ${({isDarkMode}) => isDarkMode ? '#111' : '#fff'};
    color: ${({isDarkMode}) => isDarkMode ? '#07a2f4' : 'black'};
    text-align: center;
    overflow: hidden;

    h1 {
        font-size: 3rem;
        font-family: 'Orbitron', sans-serif;
        color: #07a2f4; /* Subtle green accent */
        text-shadow: 0 0 10px rgb(7, 162, 244); /* Soft glow */

        @media (max-width: 768px) {
            font-size: 2rem;
        }

        @media (max-width: 480px) {
            font-size: 1.5rem;
        }
    }

    p {
        font-size: 1.2rem;
        margin-top: 20px;
        font-family: 'Roboto', sans-serif;
        max-width: 600px;
        color: #ccc; /* Softer text color */
        padding: 0 10px;

        @media (max-width: 768px) {
            font-size: 1.1rem;
        }

        @media (max-width: 480px) {
            font-size: 1rem;
        }
    }

    a {
        margin-top: 30px;
        padding: 15px 30px;
        background-color: #07a2f4;
        color: black;
        border-radius: 5px;
        text-decoration: none;
        font-weight: bold;
        transition: transform 0.3s ease;

        &:hover {
            background-color: #0175fa;
        }

        @media (max-width: 768px) {
            padding: 10px 20px;
        }

        @media (max-width: 480px) {
            padding: 8px 15px;
        }
    }
`;

// Faint flowing background effect (subtle motion)
const SubtleBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(0, 255, 65, 0.1) 10%, transparent 90%), 
                    linear-gradient(to bottom, rgba(0, 255, 65, 0.05) 10%, transparent 90%);
  background-size: 400% 400%;
  animation: ${backgroundFlow} 15s linear infinite;
  z-index: -1;
`;

// Floating lock icon for encryption symbol
const FloatingLockIcon = styled(FaLock)`
  position: absolute;
  top: 20%;
  left: 10%;
  font-size: 120px;
  color: rgba(0, 255, 65, 0.1); /* Soft green */
  animation: float 6s ease-in-out infinite;
  z-index: -1;
  
  @keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 30px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    justify-content: center;
    gap: 10px;
  }
`;
