import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { FiMenu, FiX } from 'react-icons/fi'; // Icons for the menu toggle
import logo from '../assets/logo512.png'; // Adjust the path based on your project structure

const Header = () => {
    const { isDarkMode } = useTheme();
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <NavBar>
            <LogoContainer>
                <Logo src={logo} alt="YourDiary" />
            </LogoContainer>
            <Hamburger onClick={toggleMenu}>
                {isMenuOpen ? <FiX size={30} /> : <FiMenu size={30} />}
            </Hamburger>
            <NavLinks isOpen={isMenuOpen}>
                <NavLink to="/" onClick={toggleMenu}>Home</NavLink>
                <NavLink to="/about" onClick={toggleMenu}>About</NavLink>
                <NavLink to="/contact" onClick={toggleMenu}>Contact</NavLink>
            </NavLinks>
        </NavBar>
    );
};

export default Header;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.8)' : 'black')}; /* Ensure black background in both modes */
  color: white; /* Force white text */
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const NavLink = styled(Link)`
    margin-left: 20px;
    color: white; /* Ensure white text */
    text-decoration: none;
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;

    @media (max-width: 768px) {
        margin: 15px 0;
        font-size: 1.5rem;
        color: white; /* Keep text white in mobile view */
    }

    &:hover {
        color: #61dafb; /* Light blue hover effect */
    }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  height: 50px;
  width: auto;
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  margin-right: 30px;
  
  @media (max-width: 768px) {
    display: block;
    color: white; /* Ensure the icon is white */
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;

  /* Hide the links on mobile */
  @media (max-width: 768px) {
    position: absolute;
    top: 60px;
    right: 0;
    flex-direction: column;
    background-color: ${({ isDarkMode }) => (isDarkMode ? 'rgba(0, 0, 0, 0.9)' : 'black')}; /* Ensure dark background in mobile view */
    width: 100%;
    height: ${({ isOpen }) => (isOpen ? 'calc(100vh - 60px)' : '0')};
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.3s ease;
    z-index: 999;
  }
`;
